<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">ข้อมูลลูกค้า</h4>

              <div class="row mt-3">
                <form-wizard color="#5b73e8" step-size="sm">
                  <tab-content icon="mdi mdi-numeric-1" title="ข้อมูลส่วนบุคคล">
                    <div class="row">
                      <div class="col-sm-6 col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="locationName">ที่ปรึกษาการขาย:</label>
                          <input
                            disabled
                            v-model="form.sale"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6">
                        <div class="mb-3 position-relative">
                          <label for="locationName">สถานที่ให้ข้อมูล:</label>
                          <input
                            v-model="form.locationName"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="provinceId">จังหวัด:</label>
                          <multiselect
                            @input="getAmphuresLo(form.provinceLocationId)"
                            id="provinceId"
                            v-model="form.provinceLocationId"
                            :options="provinces"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="amphurId">อำเภอ:</label>
                          <multiselect
                            @input="getDistrictsLo(form.amphurLocationId)"
                            id="amphurId"
                            v-model="form.amphurLocationId"
                            :options="amphuresLo"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="districtId">ตำบล:</label>
                          <multiselect
                            id="districtId"
                            v-model="form.districtIdLocationId"
                            :options="districtsLo"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <!-- <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="ctJobDate">วันที่:</label>
                          <date-picker
                            disabled
                            v-model="form.date"
                            :first-day-of-week="1"
                            format="YYYY-MM-DD"
                            value-type="format"
                            lang="en"
                          >
                          </date-picker>
                        </div>
                      </div> -->
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-6 col-md-6 col-xl-4">
                        <div class="mb-3 position-relative">
                          <code>* </code><label for="branchId">สาขา:</label>
                          <multiselect
                            disabled
                            id="branchId"
                            v-model="form.branchId"
                            :options="localData"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                            @input="getSearch()"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>

                      <div class="col-sm-4 col-md-3 col-xl-2">
                        <div class="mb-3 position-relative">
                          1.<label for="prefixOPtion">คำนำหน้า:</label>
                          <multiselect
                            placeholder=""
                            @input="ChangeNameTh(form.prefixId)"
                            v-model="form.prefixId"
                            :options="prefixOPtion"
                            label="nameTh"
                            :show-labels="false"
                          >
                          </multiselect>
                        </div>
                      </div>
                      <div class="col-sm-4 col-md-3 col-xl-3">
                        <div class="mb-3 position-relative">
                          <code>* </code><label for="nameTh">ชื่อ:</label>
                          <input
                            v-model="form.nameTh"
                            type="text"
                            class="form-control"
                            placeholder=""
                            :class="{
                              'is-invalid': submitform && $v.form.nameTh.$error,
                            }"
                          />
                          <div
                            v-if="submitform && $v.form.nameTh.$error"
                            class="invalid-tooltip"
                          >
                            <span
                              v-if="
                                !$v.form.nameTh.required ||
                                !$v.form.nameTh.maxLength
                              "
                              >{{ error }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4 col-md-3 col-xl-3">
                        <div class="mb-3 position-relative">
                          <label for="validationTooltiplame">นามสกุล:</label>
                          <input
                            v-model="form.familyNameTh"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>

                      <div class="col-sm-4 col-md-3 col-xl-2">
                        <div class="mb-3 position-relative">
                          2. <label for="gender"> เพศ:</label>
                          <multiselect
                            id="gender"
                            v-model="form.gender"
                            :options="genderOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                            placeholder=""
                          ></multiselect>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-4 col-xl-2">
                        <div class="mb-3 position-relative">
                          3. <label for="age">อายุ:</label>
                          <input
                            v-model="form.age"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 col-sm-3">
                        <div class="mb-3 position-relative">
                          <label for="buildingNumber"
                            >4. ที่อยู่ บ้านเลขที่:</label
                          >
                          <input
                            id="buildingNumber"
                            v-model="form.buildingNumber"
                            type="text"
                            class="form-control"
                            value=""
                          />
                          <span class="text-muted"
                            ><strong><code>999/999</code></strong></span
                          >
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-3">
                        <div class="mb-3 position-relative">
                          <label for="townshipNumber">หมู่:</label>
                          <input
                            :maxLength="10"
                            :minlength="1"
                            id="townshipNumber"
                            v-model="form.townshipNumber"
                            type="text"
                            class="form-control"
                            value=""
                          />
                          <span class="text-muted"
                            ><strong><code>ตัวอย่าง 1,2,3</code></strong></span
                          >
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="townshipName">ชื่อหมู่บ้าน:</label>
                          <input
                            id="townshipName"
                            v-model="form.townshipName"
                            type="text"
                            class="form-control"
                            value=""
                          />
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="subLane">ตรอก:</label>
                          <input
                            id="subLane"
                            v-model="form.subLane"
                            type="text"
                            class="form-control"
                            value=""
                          />
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="lane">ซอย:</label>
                          <input
                            id="lane"
                            v-model="form.lane"
                            type="text"
                            class="form-control"
                            value=""
                          />
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="streetName">ถนน:</label>
                          <input
                            id="streetName"
                            v-model="form.streetName"
                            type="text"
                            class="form-control"
                            value=""
                          />
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="provinceId">จังหวัด:</label>
                          <multiselect
                            @input="getAmphures(provinceId)"
                            id="provinceId"
                            v-model="provinceId"
                            :options="provinces"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="amphurId">อำเภอ:</label>
                          <multiselect
                            @input="getDistricts(amphurId)"
                            id="amphurId"
                            v-model="amphurId"
                            :options="amphures"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="districtId">ตำบล:</label>
                          <multiselect
                            id="districtId"
                            v-model="districtId"
                            :options="districts"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="postCode">รหัสไปรษณีย์:</label>
                          <input
                            id="postCode"
                            v-model="form.postCode"
                            maxlength="5"
                            type="text"
                            class="form-control"
                            value=""
                            :class="{
                              'is-invalid':
                                submitform && $v.form.postCode.$error,
                            }"
                          />

                          <div
                            v-if="submitform && $v.form.postCode.$error"
                            class="invalid-tooltip"
                          >
                            <span
                              v-if="
                                !$v.form.postCode.numeric ||
                                !$v.form.postCode.minLength ||
                                !$v.form.postCode.maxLength
                              "
                              >{{ error }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="mb-3 position-relative">
                          <label for="fullAddress">ที่อยู่เต็ม:</label>
                          <!-- disabled="disabled"  -->
                          <textarea
                            disabled
                            v-model="fullAddress"
                            class="form-control"
                            name="textarea"
                            rows="3"
                          ></textarea>
                          <p></p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-md-3 col-xxl-3">
                        <div class="mb-3 position-relative">
                          5.<label for="mobilePhone">เบอร์โทรศัพท์:</label>
                          <input
                            maxlength="10"
                            v-model="form.mobilePhone"
                            type="tel"
                            class="form-control input-placeholder"
                            placeholder="เบอร์โทรศัพท์แบบไม่มีขีด"
                            :class="{
                              'is-invalid':
                                submitform && $v.form.mobilePhone.$error,
                            }"
                          />
                          <div
                            v-if="submitform && $v.form.mobilePhone.$error"
                            class="invalid-tooltip"
                          >
                            <span
                              v-if="
                                !$v.form.mobilePhone.numeric ||
                                !$v.form.mobilePhone.maxLength ||
                                !$v.form.mobilePhone.minLength
                              "
                              >{{ error }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-3 col-xl-3 col-xxl-2">
                        <div class="mb-3 position-relative">
                          6.
                          <label for="validationTooltipenflname"
                            >Facebook:</label
                          >
                          <input
                            v-model="form.facebookId"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-3 col-xl-3 col-xxl-2">
                        <div class="mb-3 position-relative">
                          <label for="validationTooltipenflname">Line:</label>
                          <input
                            v-model="form.lineId"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>

                      <div class="col-sm-6 col-md-3 col-xl-3 col-xxl-2">
                        <div class="mb-3 position-relative">
                          7.
                          <label for="familyMembers">สมาชิกในครอบครัว:</label>
                          <input
                            v-model="form.familyMembers"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-xl-5 col-xxl-3">
                        <div class="mb-3 position-relative">
                          8. <label for="occId"> อาชีพ:</label>
                          <multiselect
                            id="occId"
                            v-model="form.occId"
                            :options="occOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="occId"
                            @search-change="getOccupations($event)"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-xl-5 col-xxl-2">
                        <div class="mb-3 position-relative">
                          9.
                          <label for="familySalaryId">
                            ช่วงรายได้ของครอบครัว:</label
                          >
                          <multiselect
                            id="familySalaryId"
                            v-model="form.familySalaryId"
                            :options="familySalaryOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                            @search-change="getFamilySalary($event)"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                    </div>
                    <!-- end col -->

                    <!-- end row -->
                  </tab-content>

                  <tab-content icon="mdi mdi-numeric-2" title="รถคันปัจจุบัน">
                    <div class="row">
                      <div class="col-sm-6 col-md-4 col-xl-3 col-xxl-2">
                        <div class="mb-3 position-relative">
                          10.<label for="usedBrand">
                            ยี่ห้อที่ใช้ในปัจจุบัน:</label
                          >
                          <multiselect
                            id="usedBrand"
                            v-model="form.usedBrand"
                            :options="usedBrandOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="brandId"
                            @search-change="getUsedBrand($event)"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xl-4 col-xxl-3">
                        <div class="mb-3 position-relative">
                          11.<label for="usedVehicleTypeId">
                            ประเภทรถที่ใช้ในปัจจุบัน:</label
                          >
                          <multiselect
                            id="usedVehicleTypeId"
                            v-model="form.usedVehicleTypeId"
                            :options="usedVehicleOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="typeId"
                            @search-change="getUsedVehicle($event)"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xl-3 col-xl-2">
                        <div class="mb-3 position-relative">
                          12.<label for="objId">
                            วัตถุประสงค์การใช้งานรถ:</label
                          >
                          <multiselect
                            id="objId"
                            v-model="form.purposeId"
                            :options="objecOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="purposeId"
                            @search-change="getPurposes($event)"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                    </div>
                    <!-- end row -->
                  </tab-content>
                  <tab-content
                    icon="mdi mdi-numeric-3"
                    title="พฤติกรรมในการตัดสินใจซื้อรถยนต์"
                  >
                    <div class="row">
                      <div class="col-sm-6 col-md-4 col-xl-3">
                        <div class="mb-3 position-relative">
                          13.<label for="objId"> เหตุผลการวางแผนซื้อรถ:</label>
                          <multiselect
                            id="objId"
                            v-model="form.reasonId"
                            :options="objOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="objId"
                            @search-change="getObj($event)"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xl-3">
                        <div class="mb-3 position-relative">
                          14.<label for="objId"> ระยะเวลาที่วางแผนซื้อ:</label>
                          <multiselect
                            id="duration"
                            v-model="form.duration"
                            :options="durationVehicleOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="id"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xl-3 col-xxl-2">
                        <div class="mb-3 position-relative">
                          15.<label for="otherVehicleTypeId">
                            ประเภทรถอื่นๆ ที่สนใจ:</label
                          >
                          <multiselect
                            id="otherVehicleTypeId"
                            v-model="form.otherVehicleTypeId"
                            :options="otherVehicleOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="typeId"
                            @search-change="getOtherVehicle($event)"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xl-3 col-xxl-2">
                        <div class="mb-3 position-relative">
                          16.<label for="usedBrand">
                            ยี่ห้ออื่นๆ ที่สนใจ:</label
                          >
                          <multiselect
                            id="usedBrand"
                            v-model="form.otherBrandId"
                            :options="usedBrandOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="brandId"
                            @search-change="getUsedBrand($event)"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xl-3 col-xxl-2">
                        <div class="mb-3 position-relative">
                          17.<label for="usedBrand">
                            ปัจจัยในการตัดสินใจซื้อรถ:</label
                          >
                          <multiselect
                            id="usedBrand"
                            v-model="form.factorId"
                            :options="factorOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="factorId"
                            @search-change="getFactor($event)"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xl-3 col-xxl-2">
                        <div class="mb-3 position-relative">
                          18.<label for="usedBrand">
                            แคมเปญ ทำให้ตัดสินใจซื้อรถ:</label
                          >
                          <multiselect
                            id="usedBrand"
                            v-model="form.campId"
                            :options="campOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="campaignId"
                            @search-change="getCampaign($event)"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xl-3 col-xxl-2">
                        <div class="mb-2">
                          <label class="form-label d-block mb-3"
                            >19.ทดลองขับรถยนต์มิตซูบิชิ:</label
                          >
                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="form.testDrive"
                              id="testDrive"
                              name="outer-group[0][testDrive]"
                              class="mb-3"
                              plain
                              value="1"
                              unchecked-value="0"
                              >เคย</b-form-radio
                            >
                          </div>
                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="form.testDrive"
                              id="testDrive2"
                              name="outer-group[0][testDrive]"
                              class="mb-3"
                              value="0"
                              plain
                              >ไม่เคย</b-form-radio
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xl-3 col-xxl-2">
                        <div class="mb-3 position-relative">
                          20.<label for="usedBrand">
                            ยี่ห้อที่เคยเข้าร่วมกิจกรรม:</label
                          >
                          <multiselect
                            id="usedBrand"
                            v-model="form.brandEventId"
                            :options="usedBrandOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="brandId"
                            @search-change="getUsedBrand($event)"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xl-3 col-xxl-3">
                        <div class="mb-3 position-relative">
                          21.<label for="eventId"> รูปแบบกิจกรรม:</label>
                          <multiselect
                            id="eventId"
                            v-model="form.eventId"
                            :options="eventOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                            @search-change="getEvent($event)"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xl-3 col-xxl-3">
                        <div class="mb-3 position-relative">
                          22.<label for="eventId">
                            รูปแบบกิจกรรมที่สนใจเข้าร่วม:</label
                          >
                          <multiselect
                            id="eventId"
                            v-model="form.otherEventId"
                            :options="eventOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                            @search-change="getEvent($event)"
                          ></multiselect>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xl-3 col-xxl-2">
                        <div class="mb-3 position-relative">
                          23.<label for="srcInfoId">
                            ได้รับข้อมูลจากแหล่งใด:</label
                          >
                          <multiselect
                            id="srcInfoId"
                            v-model="form.srcInfoId"
                            :options="srcInOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                            @search-change="getSrcIn($event)"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>

                      <div class="col-sm-6 col-md-4 col-xl-3 col-xxl-2">
                        <div class="mb-3 position-relative">
                          24.<label for="hobby">งานอดิเรก:</label>
                          <input
                            v-model="form.hobby"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-5 col-xl-4 col-xxl-3">
                        <div class="mb-3 position-relative">
                          25.<label for="peopleAdvice"
                            >ผู้ที่ขอคำแนะนำในการตัดสินใจซื้อรถ:</label
                          >
                          <input
                            v-model="form.peopleAdvice"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="col-sm-4 col-md-4 col-xl-3 col-xxl-2">
                        <div class="mb-2">
                          <label class="form-label d-block mb-3"
                            >26.โชว์รูมมิตซูบิชิ:</label
                          >
                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="form.showroom"
                              id="showroom"
                              name="outer-group[0][showroom]"
                              class="mb-3"
                              plain
                              value="1"
                              unchecked-value="0"
                              >รู้จัก</b-form-radio
                            >
                          </div>
                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="form.showroom"
                              id="showroom2"
                              name="outer-group[0][showroom]"
                              class="mb-3"
                              value="0"
                              plain
                              >ไม่รู้จัก</b-form-radio
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6 col-xl-5">
                        <div class="mb-3 position-relative">
                          <label for="validationTooltipnote">หมายเหตุ:</label>

                          <textarea
                            v-model="form.note"
                            id="formmessage"
                            rows="3"
                            class="form-control"
                          >
                          </textarea>
                        </div>
                      </div>
                    </div>

                    <!-- end row -->
                  </tab-content>
                  <template slot="footer" slot-scope="props">
                    <div class="wizard-footer-left">
                      <button
                        v-if="props.activeTabIndex > 0"
                        :style="props.fillButtonStyle"
                        @click="props.prevTab()"
                        class="btn"
                      >
                        ย้อนกลับ
                      </button>
                    </div>
                    <div class="wizard-footer-right">
                      <button
                        v-if="!props.isLastStep"
                        @click="validateNextTab(props)"
                        class="btn"
                        :style="props.fillButtonStyle"
                      >
                        ต่อไป
                      </button>

                      &nbsp;
                    </div>
                    <div class="wizard-footer-right">
                      <button
                        type="submit"
                        class="btn btn-success"
                        @click="Form"
                      >
                        บันทึก
                      </button>
                      &nbsp;
                    </div>
                  </template>
                </form-wizard>
                <!-- <div class="text-end br">
                  <button type="submit" class="btn btn-success" @click="Form">
                    บันทึก
                  </button>
                  &nbsp;
                </div> -->
              </div>
            </div>

            <hr />
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>

<script>
import {
  required,
  // email,
  minLength,
  // sameAs,
  maxLength,
  numeric,
  // url,
  // alphaNum,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    FormWizard,
    TabContent,
    // DatePicker,
    /*Switches,
    NumberInputSpinner,*/
  },
  page: {
    title: appConfig.marketScan,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  data() {
    return {
      Hide: false,
      enabled: false,
      submitform: false,
      submitformAddress: false,
      modalUpdateAddress: false,
      submitUpdateAddress: false,

      title: "แก้ไขข้อมูลสำรวจตลาด",
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
          active: true,
        },
        {
          text: appConfig.marketScan,
          href: "/market-scan",
        },
        {
          text: "แก้ไขข้อมูลสำรวจตลาด",
          active: true,
        },
      ],
      localDataBranch: [], //เป็น-array Id
      localData: [],
      value: null,
      value1: null,
      dataTest: [],
      options: [],

      startIndex: "",
      endIndex: "",
      loading: undefined,
      total_pages: "",
      page: "",
      per_page: "",
      totalRecord: "",
      rowData: [], //? Data ที่ต่อ API
      // rowUser: [],
      rowAddress: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",
      mkScanId: this.$route.params.mkScanId,
      selectedUsers: [],
      isSelectedAll: false,

      selectMode: "multi",
      selected: [],

      fields: [
        {
          key: "buildingNumber",
          sortable: true,
          label: "บ้านเลขที่",
        },
        {
          key: "districtNameTh",
          sortable: true,
          label: "ตำบล",
        },
        {
          key: "amphurNameTh",
          sortable: true,
          label: "อำเภอ",
        },
        {
          key: "provinceNameTh",
          sortable: true,
          label: "จังหวัด",
        },
        {
          key: "action",
          label: "แก้ไข | ลบ ",
        },
      ],

      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",
      readinessOption: [
        { text: "สูง", value: "3" },
        { text: "กลาง", value: "2" },
        { text: "ต่ำ", value: "1" },
        { text: "ยังไม่พร้อม", value: "0" },
      ],
      lavelOption: [
        { text: "สูง", value: "3" },
        { text: "กลาง", value: "2" },
        { text: "ต่ำ", value: "1" },
        { text: "อื่นๆ", value: "0" },
      ],

      occOption: [],
      salaryOption: [
        { nameTh: "ต่ำกว่า 10,000", id: 0 },
        { nameTh: "10,001-30,000", id: 1 },
        { nameTh: "30,001-50,000", id: 2 },
        { nameTh: "50,001-100,000", id: 3 },
        { nameTh: "100,000 บาทขึ้นไป", id: 4 },
      ],
      cusSrcOption: [],
      prefixOPtion: [],
      provinces: [],
      amphures: [],
      districts: [],
      residentOption: [
        { nameTh: "เช่า", id: "R" },
        { nameTh: "อาศัยอยู่บ้านตัวเอง", id: "H" },
        { nameTh: "อาศัยอยู่กับญาติ", id: "RH" },
        { nameTh: "อาศัยอยู่กับผู้อื่น", id: "OH" },
      ],
      maritalOption: [
        { nameTh: "โสด", id: "S" },
        { nameTh: "แต่งงานแล้ว", id: "M" },
        { nameTh: "หย่าร้าง", id: "D" },
        { nameTh: "หม้าย", id: "W" },
      ],
      genderOption: [
        { nameTh: "ชาย", id: "M" },
        { nameTh: "หญิง", id: "F" },
        { nameTh: "ไม่ระบุ", id: "NONE" },
      ],
      amphuresLo: [],
      salarysOption: [],
      eventOption: [],
      srcInOption: [],
      usedBrandOption: [],
      lifestyleOption: [],
      objOption: [],
      familySalaryOption: [],
      croOption: [],
      otherModelOption: [],
      otherVehicleOption: [],
      otherVehicleSubOption: [],
      usedVehicleOption: [],
      durationVehicleOption: [
        { nameTh: "ภายใน 1-2 เดือน", id: "1" },
        { nameTh: "ภายใน 3-4 เดือน", id: "2" },
        { nameTh: "ออกรถไปแล้ว/ยกเลิกจอง/ยกเลิกส่งมอง", id: "3" },
        { nameTh: "ยังไม่ได้ตัดสินใจ", id: "4" },
      ],
      regPlaceOption: [{ nameTh: "Showroom " }, { nameTh: "Road Show" }],
      hideButton: false,
      campOption: [],
      durationOption: [],
      objecOption: [],
      factorOption: [],
      overlayFlag: false,
      form: {
        lineId: "",
        facebookId: "",

        branchId: "",
        prefixId: "",
        nameTh: "",
        familyNameTh: "",
        mobilePhone: "",

        note: "",

        postCode: "",
        lane: "",
        subLane: "",
        townshipName: "",
        townshipNumber: "",
        buildingNumber: "",

        occId: "",

        eventId: "",
        srcInfoId: "",

        familySalaryId: "",

        gender: "",
        age: "",
        usedBrand: "",
        usedVehicleTypeId: "",
        purposeId: "",
        reasonId: "",
        duration: "",
        otherVehicleTypeId: "",
        otherBrandId: "",
        factorId: "",
        campId: "",
        testDrive: "",
        brandEventId: "",
        otherEventId: "",
        showroom: "",
        hobby: "",
        peopleAdvice: "",
        familyMembers: "",
        date: "",
        amphurLocationId: "",
        locationName: "",
        provinceLocationId: "",
        sale: "",
        districtIdLocationId: "",
      },
      districtsLo: [],
      provinceId: "",
      amphurId: "",
      districtId: "",
    };
  },
  validations: {
    form: {
      branchId: {
        required,
      },
      nameTh: {
        required,
      },

      mobilePhone: {
        // required,
        numeric,
        maxLength: maxLength(10),
        minLength: minLength(10),
      },

      postCode: {
        numeric,
        minLength: minLength(5),
        maxLength: maxLength(5),
      },
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      if (user.branchId == item.branchId) {
        this.form.branchId = {
          branchId: item.branchId,
          nameTh: item.nameTh,
        };
      }
    });
    this.localDataBranchIdUser = arrayBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;

    this.getPrefixes();
    this.getProvinces();

    this.getDataShow();
  },
  computed: {
    fullAddress: function () {
      //

      return `${
        this.form.buildingNumber != "" && this.form.buildingNumber != null
          ? "" + this.form.buildingNumber
          : ""
      } ${
        this.form.townshipNumber != "" && this.form.townshipNumber != null
          ? "หมู่" + " " + this.form.townshipNumber
          : ""
      } ${
        this.form.townshipName != "" && this.form.townshipName != null
          ? "" + this.form.townshipName
          : ""
      } ${
        this.form.subLane != "" && this.form.subLane != null
          ? "ตรอก" + " " + this.form.subLane
          : ""
      } ${
        this.form.lane != "" && this.form.lane != null
          ? "ซอย" + " " + this.form.lane
          : ""
      } ${
        this.form.streetName != "" &&
        this.form.streetName != undefined &&
        this.form.streetName != null
          ? "ถนน" + " " + this.form.streetName
          : ""
      } ${
        this.districtId != "" &&
        this.districtId != undefined &&
        this.districtId != null &&
        this.districtId.nameTh != "" &&
        this.districtId.nameTh != undefined &&
        this.districtId.nameTh != null
          ? this.districtId.nameTh
          : ""
      } ${
        this.amphurId != "" &&
        this.amphurId != undefined &&
        this.amphurId != null &&
        this.amphurId.nameTh != "" &&
        this.amphurId.nameTh != undefined &&
        this.amphurId.nameTh != null
          ? this.amphurId.nameTh
          : ""
      } ${
        this.provinceId != "" &&
        this.provinceId != null &&
        this.provinceId != undefined &&
        this.provinceId.nameTh != null &&
        this.provinceId.nameTh != "" &&
        this.provinceId.nameTh != undefined
          ? this.provinceId.nameTh
          : ""
      }  ${
        this.form.postCode != "" && this.form.postCode != null
          ? "รหัสไปรษณีย์" + " " + this.form.postCode
          : ""
      }
      `;
    },
  },
  created() {
    // this.getLocalData();
  },
  methods: {
    validateNextTab(props) {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.form.$invalid != true) {
        props.nextTab();
      }
    },
    customLabelCro({ nameTh, familyName }) {
      return `${nameTh}  ${familyName ? familyName : ""}`;
    },
    customLabel({ nameTh, familyName }) {
      return `${nameTh}  ${familyName ? familyName : ""}`;
    },
    max() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const maxDate = new Date(today);

      maxDate.setDate(0);
      // console.log(maxDate);
    },
    ChangeNameTh(nameTh) {
      if (nameTh.nameTh === "นาย") {
        this.form.prefixIdEn = "Mr.";
      } else if (nameTh.nameTh === "นาง") {
        this.form.prefixIdEn = "Mrs.";
      } else {
        this.form.prefixIdEn = "Miss.";
      }
    },
    ChangeGender(nameTh) {
      if (nameTh === "M") {
        return "ชาย";
      } else if (nameTh === "F") {
        return "หญิง";
      } else if (nameTh === "NONE") {
        return "ไม่ระบุ";
      }
    },
    ChangeDuration(name) {
      if (name === 1) {
        return "ภายใน 1-2 เดือน";
      } else if (name === 2) {
        return "ภายใน 3-4 เดือน";
      } else if (name === 3) {
        return "ออกรถไปแล้ว/ยกเลิกจอง/ยกเลิกส่งมอง";
      } else if (name === 4) {
        return "ยังไม่ได้ตัดสินใจ";
      }
    },
    getSearch() {
      this.getOccupations();
      this.getEvent();
      this.getSrcIn();
      this.getUsedBrand();
      this.getObj();
      this.getFamilySalary();
      this.getOtherVehicle();
      this.getCampaign();
      this.getUsedVehicle();
      this.getFactor();
      this.getPurposes();
    },
    getDataShow: function () {
      this.overlayFlag = true;
      // this.loading = true; //skeleton true
      useNetw
        .get("api/market-scan/show", {
          params: {
            mkScanId: atob(this.mkScanId),
          },
        })
        .then((response) => {
          this.form.branchId = {
            nameTh: response.data.data.branchNameTh,
            branchId: response.data.data.branchId,
          };
          this.form.mobilePhone = response.data.data.phone;
          this.form.gender = {
            nameTh: this.ChangeGender(response.data.data.gender),
            id: response.data.data.gender,
          };
          this.form.prefixId = {
            nameTh: response.data.data.prefixNameTh,
            prefixId: response.data.data.prefixId,
          };
          this.form.nameTh = response.data.data.nameTh;
          this.form.familyNameTh = response.data.data.familyNameTh;
          this.form.age = response.data.data.age;
          this.form.facebookId = response.data.data.facebook;
          this.form.lineId = response.data.data.line;
          this.form.familyMembers = response.data.data.familyMembers;
          this.form.occId = {
            nameTh: response.data.data.occNameTh,
            occId: response.data.data.occId,
          };
          this.form.familySalaryId = {
            nameTh: response.data.data.familySalaryTh,
            salaryId: response.data.data.familySalaryId,
          };
          this.form.buildingNumber = response.data.data.buildingNumber;
          this.form.townshipNumber = response.data.data.townshipNumber;
          this.form.townshipName = response.data.data.townshipName;
          this.form.subLane = response.data.data.subLane;
          this.form.lane = response.data.data.lane;
          this.form.streetName = response.data.data.streetName;
          this.provinceId = {
            nameTh: response.data.data.provinceNameTh,
            provinceId: response.data.data.provinceId,
          };
          this.amphurId = {
            nameTh: response.data.data.amphurNameTh,
            amphureId: response.data.data.amphurId,
          };
          this.districtId = {
            nameTh: response.data.data.districtNameTh,
            districtId: response.data.data.districtId,
          };
          this.form.postCode = response.data.data.postCode;
          this.form.usedBrand = {
            nameTh: response.data.data.useBrandTh,
            brandId: response.data.data.usedBrand,
          };
          this.form.usedVehicleTypeId = {
            nameTh: response.data.data.useVehicleTypeTh,
            typeId: response.data.data.usedVehicleTypeId,
          };
          this.form.purposeId = {
            nameTh: response.data.data.purposeTh,
            purposeId: response.data.data.purposeId,
          };
          this.form.reasonId = {
            nameTh: response.data.data.buyerReasonTh,
            objId: response.data.data.reasonId,
          };
          this.form.duration = {
            nameTh: this.ChangeDuration(response.data.data.duration),
            id: response.data.data.duration,
          };
          this.form.otherVehicleTypeId = {
            nameTh: response.data.data.otherVehicleTypeTh,
            typeId: response.data.data.otherVehicleTypeId,
          };
          this.form.otherBrandId = {
            nameTh: response.data.data.otherBrandTh,
            brandId: response.data.data.otherBrandId,
          };
          this.form.factorId = {
            nameTh: response.data.data.factorTh,
            factorId: response.data.data.factorId,
          };
          this.form.campId = {
            nameTh: response.data.data.campaignTh,
            campaignId: response.data.data.campId,
          };
          this.form.testDrive = response.data.data.testDrive;
          this.form.brandEventId = {
            nameTh: response.data.data.brandEventTh,
            brandId: response.data.data.brandEventId,
          };
          this.form.eventId = {
            nameTh: response.data.data.eventNameTh,
            eventTypeId: response.data.data.eventId,
          };
          this.form.otherEventId = {
            nameTh: response.data.data.otherEventNameTh,
            eventTypeId: response.data.data.otherEventId,
          };
          this.form.srcInfoId = {
            nameTh: response.data.data.customerSourceInfoTh,
            srcInfoId: response.data.data.srcInfoId,
          };
          this.form.showroom = response.data.data.showroom;
          this.form.hobby = response.data.data.hobby;
          this.form.peopleAdvice = response.data.data.peopleAdvice;
          this.form.note = response.data.data.note;
          this.form.date = response.data.data.date;
          this.form.sale = response.data.data.createdBy;
          this.form.locationName = response.data.data.locationName;

          this.form.provinceLocationId = {
            nameTh: response.data.data.provinceLocationNameTh,
            provinceId: response.data.data.provinceLocationId,
          };
          this.form.amphurLocationId = {
            nameTh: response.data.data.amphurLocationNameTh,
            amphureId: response.data.data.amphurLocationId,
          };
          this.form.districtIdLocationId = {
            nameTh: response.data.data.districtLocationNameTh,
            districtId: response.data.data.districtLocationId,
          };
          this.getSearch();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },

    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchItem = user.branchId;

      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
    },

    Form() {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.form.$invalid != true) {
        this.postMarketScan();
      }
    },
    getPurposes: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/market-scan/dealer-purposes", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            nameTh: event,
          },
        })
        .then((response) => {
          this.objecOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getFactor: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/market-scan/dealer-factors", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            nameTh: event,
          },
        })
        .then((response) => {
          this.factorOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getOccupations: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/market-scan/branch-occupation", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.form.branchId.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.occOption = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    getEvent: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/market-scan/dealer-event-type", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.form.branchId.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.eventOption = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getCampaign: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/market-scan/branch-campaign", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.form.branchId.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.campOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getSrcIn: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/market-scan/branch-customer-source-info", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.form.branchId.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.srcInOption = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getUsedBrand: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/vehicle/brands", {
          params: {
            nameTh: event,
          },
        })
        .then((response) => {
          this.usedBrandOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    getObj: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/buyer-objectives", {
          params: {
            nameTh: event,
          },
        })
        .then((response) => {
          this.objOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getFamilySalary: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/market-scan/branch-salary", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.form.branchId.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.familySalaryOption = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    getOtherVehicle: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/vehicle/types", {
          params: {
            nameTh: event,
          },
        })
        .then((response) => {
          this.otherVehicleOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    getUsedVehicle: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/vehicle/types", {
          params: {
            nameTh: event,
          },
        })
        .then((response) => {
          this.usedVehicleOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.getAddress();
      this.startIndex = (this.currentPage - 1) * this.perPage + 1;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;
      if (this.endIndex > this.totalRecord) {
        this.endIndex = this.totalRecord;
      } else {
        return this.endIndex;
      }
      this.paginatedData = this.rowAddress.slice(
        this.startIndex,
        this.endIndex
      );
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getAddress();
      this.startIndex = (this.currentPage - 1) * this.perPage + 1;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;

      if (this.endIndex > this.totalRecord) {
        this.endIndex = this.totalRecord;
      } else {
        return this.endIndex;
      }

      this.paginatedData = this.rowAddress.slice(
        this.startIndex,
        this.endIndex
      );
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowAddress = filteredItems.length;
      this.currentPage = 1;
    },

    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    getProvinces: function () {
      //  if(this.provinceId != null && this.provinceId != "" && this.provinceId != undefined) {
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/address/provinces", {})
        .then((response) => {
          this.provinces = response.data.data;
          // console.log( this.provinces);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
      //  }
    },
    getAmphures: function (isReset) {
      if (
        this.provinceId != "" &&
        this.provinceId != undefined &&
        this.provinceId != null
      ) {
        this.loading = true; //skeleton true
        useNetw
          .get("api/master/address/amphures", {
            params: {
              provinceId: this.provinceId ? this.provinceId.provinceId:"",
            },
          })
          .then((response) => {
            this.amphures = response.data.data;

            if (isReset) {
              this.amphurId = "";
              this.districtId = "";
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            // this.overlayFlag = false;
            this.loading = false; //skeleton false
          });
      }
    },
    getAmphuresLo: function (isReset) {
      if (
        this.form.provinceLocationId != "" &&
        this.form.provinceLocationId != undefined &&
        this.form.provinceLocationId != null
      ) {
        this.loading = true; //skeleton true
        useNetw
          .get("api/master/address/amphures", {
            params: {
              provinceId: this.form.provinceLocationId
                ? this.form.provinceLocationId.provinceId
                : "",
            },
          })
          .then((response) => {
            this.amphuresLo = response.data.data;

            if (isReset) {
              this.form.amphurLocationId = "";
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            // this.overlayFlag = false;
            this.loading = false; //skeleton false
          });
      }
    },
    getDistricts: function (isReset) {
      if (
        this.provinceId != "" &&
        this.provinceId != undefined &&
        this.provinceId != null &&
        this.amphurId != null &&
        this.amphurId != ""
      ) {
        useNetw
          .get("api/master/address/districts", {
            params: {
              amphurId: this.amphurId ? this.amphurId.amphureId:"",
            },
          })
          .then((response) => {
            this.districts = response.data.data;
            if (isReset) {
              this.districtId = "";
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {});
      }
    },
    getDistrictsLo: function (isReset) {
      if (
        this.form.provinceLocationId != "" &&
        this.form.provinceLocationId != undefined &&
        this.form.provinceLocationId != null &&
        this.form.amphurLocationId != null &&
        this.form.amphurLocationId != ""
      ) {
        useNetw
          .get("api/master/address/districts", {
            params: {
              amphurId: this.form.amphurLocationId ? this.form.amphurLocationId.amphureId:"",
            },
          })
          .then((response) => {
            this.districtsLo = response.data.data;
            if (isReset) {
              this.form.districtIdLocationId = "";
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {});
      }
    },
    getPrefixes: function () {
      useNetw
        .get("api/master/person/prefixes", {})
        .then((response) => {
          this.prefixOPtion = response.data.data;
          // console.log(this.prefixOPtion);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },

    postMarketScan: function () {
      this.overlayFlag = true;

      useNetw
        .put("api/market-scan/update", {
          mkScanId: atob(this.mkScanId),
          prefixId:
            this.form.prefixId.prefixId != undefined
              ? this.form.prefixId.prefixId
              : "",
          nameTh: this.form.nameTh,
          familyNameTh: this.form.familyNameTh,
          nameEn: this.form.nameEn,
          phone: this.form.mobilePhone,
          note: this.form.note,
          buildingNumber: this.form.buildingNumber,
          townshipNumber: this.form.townshipNumber,
          townshipName: this.form.townshipName,
          subLane: this.form.subLane,
          lane: this.form.lane,
          streetName: this.form.streetName,
          districtId: this.districtId ? this.districtId.districtId : "",
          amphurId: this.amphurId ? this.amphurId.amphureId : "",
          provinceId: this.provinceId ? this.provinceId.provinceId : "",
          postCode: this.form.postCode,
          fullAddress: this.fullAddress,
          occId: this.form.occId ? this.form.occId.occId : "",
          facebook: this.form.facebookId,
          line: this.form.lineId,
          eventId: this.form.eventId ? this.form.eventId.eventTypeId : "",
          srcInfoId: this.form.srcInfoId ? this.form.srcInfoId.srcInfoId : "",
          usedBrand: this.form.usedBrand ? this.form.usedBrand.brandId : "",
          familySalaryId: this.form.familySalaryId
            ? this.form.familySalaryId.salaryId
            : "",
          otherVehicleTypeId: this.form.otherVehicleTypeId
            ? this.form.otherVehicleTypeId.typeId
            : "",
          usedVehicleTypeId: this.form.usedVehicleTypeId
            ? this.form.usedVehicleTypeId.typeId
            : "",
          gender: this.form.gender.id,
          age: this.form.age,
          familyMembers: this.form.familyMembers,
          purposeId: this.form.purposeId ? this.form.purposeId.purposeId : "",
          reasonId: this.form.reasonId ? this.form.reasonId.objId : "",
          duration: this.form.duration ? this.form.duration.id : "",
          otherBrandId: this.form.otherBrandId
            ? this.form.otherBrandId.brandId
            : "",
          factorId: this.form.factorId ? this.form.factorId.factorId : "",
          testDrive: this.form.testDrive,
          brandEventId: this.form.brandEventId
            ? this.form.brandEventId.brandId
            : "",
          otherEventId: this.form.otherEventId
            ? this.form.otherEventId.eventTypeId
            : "",
          campId: this.form.campId ? this.form.campId.campaignId : "",
          hobby: this.form.hobby,
          peopleAdvice: this.form.peopleAdvice,
          showroom: this.form.showroom,
          date: this.form.date,
          districtLocationId: this.form.districtIdLocationId
            ? this.form.districtIdLocationId.districtId
            : "",
          amphurLocationId: this.form.amphurLocationId
            ? this.form.amphurLocationId.amphureId
            : "",
          provinceLocationId: this.form.provinceLocationId
            ? this.form.provinceLocationId.provinceId
            : "",
          locationName: this.form.locationName,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          this.$router.push({
            name: "market-scan",
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    clearAddressPost() {
      this.addressform.buildingNumber = "";
      this.addressform.townshipNumber = "";
      this.addressform.townshipName = "";
      this.addressform.subLane = "";
      this.addressform.lane = "";
      this.addressform.streetName = "";
      this.addressform.districtId = "";
      this.addressform.amphurId = "";
      this.addressform.provinceId = "";
      this.addressform.postCode = "";
      // this.fullAddress = "";
      this.submitformAddress = false;
    },
  },
};
</script>
<style scoped>
.input-placeholder {
  font-size: 12px;
}
.br {
  padding-right: 20px;
}
</style>
